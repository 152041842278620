import { UserRole } from '../helpers/authHelper';

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ar', name: 'Arabic', direction: 'rtl' }
];
// const firebaseConfigQA = {
//   apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
//   authDomain: 'supply-qa.firebaseapp.com',
//   projectId: 'supply-qa',
//   storageBucket: 'supply-qa.appspot.com',
//   messagingSenderId: '428145837378',
//   appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
// };
const firebaseConfigQA = {
  apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
  authDomain: 'supply-qa.firebaseapp.com',
  projectId: 'supply-qa',
  storageBucket: 'supply-qa.appspot.com',
  messagingSenderId: '428145837378',
  appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
};
const firebaseConfigDevOld = {
  apiKey: 'AIzaSyCADXDR33XJC3gCS3iaFLWytXo2m4aB_OM',
  authDomain: 'searchvm-dev.firebaseapp.com',
  databaseURL: 'https://searchvm-dev.firebaseio.com',
  projectId: 'searchvm-dev',
  storageBucket: 'searchvm-dev.appspot.com',
  messagingSenderId: '679987494918',
  appId: '1:679987494918:web:79f0c7ed8b4afc2f368387'
};


const firebaseConfigDev = {
  apiKey: "AIzaSyBXprkSsdWnA8Ks9HJ7XAjAvt9bcO_iAkQ",
  authDomain: "stylishopdev.firebaseapp.com",
  projectId: "stylishopdev",
  storageBucket: "stylishopdev.appspot.com",
  messagingSenderId: "640935596576",
  appId: "1:640935596576:web:393a09830cb8b64e672245",
  measurementId: "G-WDEYHW4RET"
};
const firebaseConfigProd = {
  apiKey: 'AIzaSyAYZumsppN9Yu-a7vSLlMrP65dlAj2R-Rg',
  authDomain: 'supplyapps-prod.firebaseapp.com',
  databaseURL: 'https://supplyapps-prod.firebaseio.com',
  projectId: 'supplyapps-prod',
  storageBucket: 'supplyapps-prod.appspot.com',
  messagingSenderId: '945566594699',
  appId: '1:945566594699:web:1cff3c482c9b69d684c68e'
};
const firebaseConfigUAT = {
  apiKey: 'AIzaSyBYAfWIyGi3aR8BI4RLGSURQSiu_1O7FqY',
  authDomain: 'supplyqa01.firebaseapp.com',
  projectId: 'supplyqa01',
  storageBucket: 'supplyqa01.appspot.com',
  messagingSenderId: '108359217635',
  appId: '1:108359217635:web:c80e2030cd49cbaa4070d6'
};

export const firebaseConfig =
  process.env.NODE_ENV === 'development'
    ? firebaseConfigDev
    : process.env.NODE_ENV === 'uat'
    ? firebaseConfigUAT
    : process.env.NODE_ENV === 'production'
    ? firebaseConfigProd
    : firebaseConfigQA;

export const currentUser = {
  id: 1,
  title: '',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = process.env.MULIN_API_HOST;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel'
];
